// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".save-status_save-now_2shdk {\n    font-family: \"Helvetica Neue\", Helvetica, Arial, sans-serif;\n    cursor: pointer;\n}\n", ""]);
// Exports
exports.locals = {
	"save-now": "save-status_save-now_2shdk",
	"saveNow": "save-status_save-now_2shdk"
};
module.exports = exports;
